@tailwind base;
@tailwind components;
@tailwind utilities;

.css-1wc848c-MuiFormHelperText-root{
    margin-left: 0px !important;
    margin-right: 0px !important;
    background-color: transparent !important;
}

.plaidbutton button{
    background-color: transparent !important;
    padding: 0px !important;
    width: 100% !important;
    height: 100% !important;
    border: 0px !important
}